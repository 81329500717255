import React from "react";
import {Helmet} from 'react-helmet'

import Layout from '../components/layout.js'

const AboutPage=() => {
    return (
        <Layout>
            <Helmet>
                <title>Wulkanizacja Tychy - Polityka cookies</title>

                <link rel="canonical" href="https://oponytychy.pl/polityka-cookies" />
                <meta name="description" content="Dbamy o Twoje bezpieczeństwo niezależnie od tego, czy są to naprawy opon, czy też przechowywanie danych." />
            </Helmet>

            <section id="content">
                <div className="indent-bottom">
                    <div className="main border-rad">
                        <div className="container_12">
                            <div className="wrapper indent-top5">
                                <div className="grid_12">
                                    <div className="indent-right48">
                                        <h2 className="p4">Polityka plikow cookies</h2>
                                        <p className="p4">Serwis nie zbiera w sposób automatyczny żadnych informacji, z wyjątkiem   informacji zawartych w plikach cookies.Pliki cookies (tzw. „ciasteczka”)   stanowią dane informatyczne, w szczególności pliki tekstowe, które   przechowywane są w urządzeniu końcowym Użytkownika Serwisu i   przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies   zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas   przechowywania ich na urządzeniu końcowym oraz unikalny numer. Podmiotem   zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies   oraz uzyskującym do nich dostęp jest operator KostaAuto.</p>
                                        <br />
                                        <p className="p4">Pliki cookies wykorzystywane są w celu:</p>
                                        <p className="p4">1). dostosowania   zawartości stron internetowych Serwisu do preferencji Użytkownika oraz   optymalizacji korzystania ze stron internetowych; w szczególności pliki   te pozwalają rozpoznać urządzenie Użytkownika Serwisu i odpowiednio   wyświetlić stronę internetową, dostosowaną do jego indywidualnych   potrzeb;</p>
                                        <p className="p4">2). tworzenia statystyk, które pomagają zrozumieć, w jaki   sposób Użytkownicy Serwisu korzystają ze stron internetowych, co   umożliwia ulepszanie ich struktury i zawartości;</p>
                                        <p className="p4">3). utrzymanie   sesji Użytkownika Serwisu (po zalogowaniu), dzięki której Użytkownik nie   musi na każdej podstronie Serwisu ponownie wpisywać loginu i hasła;</p>
                                        <p className="p4">W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne”  (session cookies) oraz „stałe” (persistent cookies).   Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w   urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony   internetowej lub wyłączenia oprogramowania (przeglądarki internetowej).   „Stałe” pliki cookies przechowywane są w urządzeniu końcowym   Użytkownika przez czas określony w parametrach plików cookies lub do   czasu ich usunięcia przez Użytkownika. </p>
                                        <br />
                                        <p className="p4">W ramach Serwisu stosowane są następujące rodzaje plików cookies:</p>
                                        <p className="p4">1). „niezbędne”   pliki cookies, umożliwiające korzystanie z usług dostępnych w ramach   Serwisu, np. uwierzytelniające pliki cookies wykorzystywane do usług   wymagających uwierzytelniania w ramach Serwisu;</p>
                                        <p className="p4">2). pliki cookies   służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania   nadużyć w zakresie uwierzytelniania w ramach Serwisu;</p>
                                        <p className="p4">3). „wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych Serwisu;</p>
                                        <p className="p4">4). „funkcjonalne”   pliki cookies, umożliwiające „zapamiętanie” wybranych przez Użytkownika   ustawień i personalizację interfejsu Użytkownika, np. w zakresie   wybranego języka lub regionu, z którego pochodzi Użytkownik, rozmiaru   czcionki, wyglądu strony internetowej itp.;</p>
                                        <p className="p4">W wielu   przypadkach oprogramowanie służące do przeglądania stron internetowych   (przeglądarka internetowa) domyślnie dopuszcza przechowywanie plików   cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą   dokonać w każdym czasie zmiany ustawień dotyczących plików cookies.   Ustawienia te mogą zostać zmienione w szczególności w taki sposób, aby   blokować automatyczną obsługę plików cookies w ustawieniach przeglądarki   internetowej bądź informować o ich każdorazowym zamieszczeniu w   urządzeniu Użytkownika Serwisu. Szczegółowe informacje o możliwości i   sposobach obsługi plików cookies dostępne są w ustawieniach   oprogramowania (przeglądarki internetowej). </p>
                                        <p className="p4">Operator Serwisu informuje, że ograniczenia stosowania plików cookies   mogą wpłynąć na niektóre funkcjonalności dostępne na stronach   internetowych Serwisu. </p>
                                        <p className="p4">Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu i   wykorzystywane mogą być również przez współpracujących z operatorem   Serwisu reklamodawców oraz partnerów. </p>
                                        <p className="p4">Więcej informacji na temat plików cookies dostępnych jest pod adresem <a href="https://wszystkoociasteczkach.pl/" aria-label="dowiedz się więcej czym są ciasteczka">www.wszystkoociasteczkach.pl</a> lub w sekcji „Pomoc” w menu przeglądarki internetowej. </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default AboutPage
